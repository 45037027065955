import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './ContentContainer.module.scss';

/**
 *
 * The ContentContainer component is designed to maintain a consistent width for content and offers two display variants:
 *
 * Variant 1: Standard Width
 * - Small (Sm): 100% width with 24px padding on each side
 * - Medium (Md): 100% width with 32px padding on each side
 * - Large (Lg): 1280px width with 80px padding on each side
 *
 * Variant 2: Full Width (enabled by passing the isFullWidth prop as true)
 * - All breakpoints display content at 100% width with no padding
 * - Large (Lg): 100% width with a maximum width of 1920px
 */
const ContentContainer = React.forwardRef(
    (
        {
            component: Component = 'div',
            className,
            isFullWidth,
            isContentWidth,
            children,
            ...otherProps
        },
        ref,
    ) => (
        <Component
            ref={ref}
            className={classnames(
                styles['content-container'],
                isFullWidth && styles['content-container--full-width'],
                isContentWidth && styles['content-container--content-width'],
                className,
            )}
            {...otherProps}
        >
            {children}
        </Component>
    ),
);

ContentContainer.displayName = 'ContentContainer';

ContentContainer.propTypes = {
    /**
     * Additional class name
     */
    className: PropTypes.string,
    /**
     * Determines if the container should be full width
     */
    isFullWidth: PropTypes.bool,
};

ContentContainer.defaultProps = {
    isFullWidth: false,
};

export default ContentContainer;
