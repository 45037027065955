import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import grid from '@/design-system/styles/tokens/grid/_grid.module.scss';

import styles from './Grid.module.scss';

/**
 *
 * The Grid Component serves as a container to define the grid template size, primarily used alongside the GridColumn component.
 *
 * Modes:
 * - Media Query: Sets the grid template using media queries.
 * - Container Query: Sets the grid template using container queries, enabled by setting the `isContainerQuery` prop to true.
 *
 * Usage:
 * - Default Usage: Employs default Design System values for columns and rows (sm: 4, md: 6, lg: 12).
 * - Custom Usage: Allows defining a custom number of columns and rows using the 'col' and 'row' props.
 *
 * Additional Features:
 * - Debug Mode: Press `Ctrl + Shift + G` to display grid template lines for debugging purposes.
 *
 * Example Usage:
 *
 * ```
 * <Grid sm={4} md={6} lg={12}>
 *     <GridColumn colSpan={{ sm: 1, md: 2, lg: 4 }}>Col 1</GridColumn>
 *     <GridColumn colSpan={{ sm: 1, md: 2, lg: 4 }}>Col 2</GridColumn>
 *     <GridColumn colSpan={{ sm: 2, md: 2, lg: 4 }}>Col 3</GridColumn>
 * </Grid>
 * ```
 */

const Grid = ({
    col: { sm: colSm, md: colMd, lg: colLg },
    row: { sm: rowSm, md: rowMd, lg: rowLg },
    isContainerQuery,
    verticalAlignment,
    debugMode,
    className,
    ...props
}) => {
    const [debugEnabled, setDebugEnabled] = useState(debugMode);

    /**
     * Callback for the keypress event listener, to enable/disable the debug mode
     * when the keyboard combination `Ctrl` + `Shift` + `G` is pressed
     */
    const debugListener = useCallback(
        (event) => {
            const { ctrlKey, shiftKey, keyCode } = event;

            if (keyCode === 71 && ctrlKey && shiftKey) {
                setDebugEnabled(!debugEnabled);
            }
        },
        [debugEnabled, setDebugEnabled],
    );

    /**
     * Hook to add an keypress event listener to dynamically set the value
     * via the keyboard press combo `Ctrl` + `Shift` + `G`
     */
    useEffect(() => {
        document.addEventListener('keydown', debugListener);

        return () => {
            document.removeEventListener('keydown', debugListener);
        };
    }, [debugListener]);

    return (
        <div
            className={classnames(
                styles['grid-wrapper'],
                isContainerQuery && styles['grid-wrapper--container-query'],
                !isContainerQuery && styles['grid-wrapper--media-query'],
            )}
        >
            <div
                className={classnames(
                    styles.grid,
                    isContainerQuery && styles['grid--container-query'],
                    !isContainerQuery && styles['grid--media-query'],
                    debugEnabled && styles['grid-debug'],
                    verticalAlignment && styles[`grid--align-${verticalAlignment}`],
                    className,
                )}
                style={{
                    '--colSm': colSm,
                    '--colMd': colMd,
                    '--colLg': colLg,
                    '--rowSm': rowSm,
                    '--rowMd': rowMd,
                    '--rowLg': rowLg,
                }}
            >
                {props.children}
            </div>
        </div>
    );
};

Grid.propTypes = {
    /**
     * Grid column template count for small, medium, and large breakpoints
     */
    col: PropTypes.shape({
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
    }),
    /**
     * Grid row template count for small medium, and large breakpoints
     */
    row: PropTypes.shape({
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
    }),
    /**
     * Verical Alignment of the Grid Items
     */
    verticalAlignment: PropTypes.oneOf(['top', 'center', 'bottom', 'stretch']),
    /**
     * Whether to use container queries or media queries
     */
    isContainerQuery: PropTypes.bool,
    /**
     * Whether to enable debug mode and display the grid template lines
     */
    debugMode: PropTypes.bool,
};

Grid.defaultProps = {
    col: {
        sm: Number(grid['column-small']),
        md: Number(grid['column-medium']),
        lg: Number(grid['column-large']),
    },
    row: {
        sm: 1,
        lg: 1,
    },
    isContainerQuery: false,
};

export default Grid;
