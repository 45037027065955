/**
 * Generates Style Modifier object from raw modifiers passed in model.json
 * 'appliedCssClassNames'
 *
 * @param {string} rawModifiers List of style modifiers authored on Component in the format of key_value
 * @returns {{ stlyeName: styleValue }} Object of keys and values
 */
export const generateStyleModifiers = (rawModifiers) => {
    const modifiers = {};

    if (rawModifiers) {
        rawModifiers.split(' ').forEach((modifier) => {
            const [key, ...val] = modifier.split('_');

            modifiers[key] = val.join('_');
        });
    }

    return modifiers;
};
