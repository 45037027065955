import React from 'react';
import ReactDOM from 'react-dom';
import { HeaderWrapper, FooterWrapper } from './components';
import { Theme } from '@/design-system/atoms/Theme';

/**
 * The main App component
 * @param {object} headerProps - The parsed props from the header's model.json
 * @param {HTMLElement} headerContainer - The container element of the header
 * @param {object} footerProps - The parsed props from the footer's model.json
 * @param {HTMLElement} footerContainer - The container element of the footer
 * @returns {ReactElement} - The header and footer components
 */
function App({ headerProps, headerContainer, footerProps, footerContainer }) {
    return (
        <>
            {headerContainer &&
                headerProps &&
                ReactDOM.createPortal(
                    <Theme className="ahs-header-footer" name={Theme.NAME.DEFAULT}>
                        <HeaderWrapper {...headerProps} />
                    </Theme>,
                    headerContainer
                )}
            {footerContainer &&
                footerProps &&
                ReactDOM.createPortal(
                    <Theme className="ahs-header-footer" name={Theme.NAME.DEFAULT}>
                        <FooterWrapper {...footerProps} />
                    </Theme>,
                    footerContainer
                )}
        </>
    );
}

export default App;
