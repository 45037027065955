import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Grid.module.scss';

/**
 * Returns a column/row position number value based on a string integer value
 * or `auto` if a string integer isn't defined
 * @param {string} position - Value to convert to a number
 * @returns {Number|string}
 */
const getStart = (position) => (isNaN(parseInt(position, 10)) ? 'auto' : parseInt(position, 10));

/**
 * The GridColumn Component is used as an element to span
 * and position itself on  a Grid component
 */
const GridColumn = ({
    colSpan: { sm: colSpanSm, md: colSpanMd, lg: colSpanLg },
    colStart: { sm: colStartSm, md: colStartMd, lg: colStartLg },
    rowSpan: { sm: rowSpanSm, md: rowSpanMd, lg: rowSpanLg },
    rowStart: { sm: rowStartSm, md: rowStartMd, lg: rowStartLg },
    className,
    ...props
}) => {
    return (
        <div
            className={classnames(styles['grid-col'], className)}
            style={{
                '--colStartSm': getStart(colStartSm),
                '--colStartMd': getStart(colStartMd),
                '--colStartLg': getStart(colStartLg),
                '--colSpanSm': colSpanSm,
                '--colSpanMd': colSpanMd,
                '--colSpanLg': colSpanLg,
                '--rowStartSm': getStart(rowStartSm),
                '--rowStartMd': getStart(rowStartMd),
                '--rowStartLg': getStart(rowStartLg),
                '--rowSpanSm': rowSpanSm,
                '--rowSpanMd': rowSpanMd,
                '--rowSpanLg': rowSpanLg,
            }}
        >
            {props.children}
        </div>
    );
};

GridColumn.propTypes = {
    /**
     * The number of columns the element should span
     * for the sm and lg breakpoints
     */
    colSpan: PropTypes.shape({
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
    }),
    /**
     * The column position the element should start on the Grid
     * for the sm and lg breakpoints
     */
    colStart: PropTypes.shape({
        sm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    /**
     * The number of rows the element should span
     * for the sm and lg breakpoints
     */
    rowSpan: PropTypes.shape({
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
    }),
    /**
     * The row position the element should start on the Grid
     * for the sm and lg breakpoints
     */
    rowStart: PropTypes.shape({
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
    }),
};

GridColumn.defaultProps = {
    colSpan: {
        sm: 1,
        md: 1,
        lg: 1,
    },
    colStart: {},
    rowSpan: {
        sm: 1,
        md: 1,
        lg: 1,
    },
    rowStart: {},
};

export default GridColumn;
