// ! automatically generated - Don't change this file manually
import PropTypes from 'prop-types';
import React from 'react';
const ICON_SIZE = { small: '1rem', medium: '1.25rem', large: '1.5rem' };;
export const IconAlertTriangle = React.forwardRef(({
  title,
  titleId: titleIdProp,
  desc,
  descId: descIdProp,
  size,
  height: heightProp,
  width: widthProp,
  ...props
}, ref) => {
  const internalTitleId = React.useId();
  const internalDescId = React.useId();
  const titleId = title != null ? titleIdProp ?? internalTitleId : undefined;
  const descId = desc != null ? descIdProp ?? internalDescId : undefined;
  const height = ICON_SIZE[size] ?? size ?? heightProp ?? "1em";
  const width = ICON_SIZE[size] ?? size ?? widthProp ?? "1em";
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}  viewBox="0 0 24 24" width={width} height={height} role={title == null ? 'presentation' : 'img'} data-icon-name="alert-triangle" ref={ref} aria-labelledby={titleId} aria-describedby={descId} {...props}>{desc ? <desc id={descId}>{desc}</desc> : null}{title ? <title id={titleId}>{title}</title> : null}<path d="M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" /><line x1={12} x2={12} y1={9} y2={13} /><line x1={12} x2={12.01} y1={17} y2={17} /></svg>;
});
IconAlertTriangle.displayName = "IconAlertTriangle";
IconAlertTriangle.propTypes = {
  /**
   * Icon title
   */
  title: PropTypes.string,
  /**
   * Icon title ID
   */
  titleId: PropTypes.string,
  /**
   * Icon description
   */
  desc: PropTypes.string,
  /**
   * Icon description ID
   */
  descId: PropTypes.string,
  /**
   * Size of the icon, these values are based on 'rem'
   * to make it adjustable to the user browser configuration.
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['small', 'medium', 'large'])])
};