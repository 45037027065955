export default typeof window !== 'undefined'
    ? window
    : {
          document: {
              body: {},
              addEventListener() {},
              removeEventListener() {},
              activeElement: {
                  blur() {},
                  nodeName: '',
              },
              querySelector() {
                  return null;
              },
              querySelectorAll() {
                  return [];
              },
              getElementById() {
                  return null;
              },
              createEvent() {
                  return {
                      initEvent() {},
                  };
              },
              createElement() {
                  return {
                      children: [],
                      childNodes: [],
                      style: {},
                      setAttribute() {},
                      getElementsByTagName() {
                          return [];
                      },
                  };
              },
              createElementNS() {
                  return {};
              },
              importNode() {
                  return null;
              },
              location: {
                  hash: '',
                  host: '',
                  hostname: '',
                  href: '',
                  origin: '',
                  pathname: '',
                  protocol: '',
                  search: '',
              },
          },
          navigator: {
              userAgent: '',
          },
          location: {
              hash: '',
              host: '',
              hostname: '',
              href: '',
              origin: '',
              pathname: '',
              protocol: '',
              search: '',
          },
          history: {
              replaceState() {},
              pushState() {},
              go() {},
              back() {},
          },
          CustomEvent: function CustomEvent() {
              return this;
          },
          addEventListener() {},
          removeEventListener() {},
          getComputedStyle() {
              return {
                  getPropertyValue() {
                      return '';
                  },
              };
          },
          Image() {},
          Date() {},
          screen: {},
          setTimeout() {},
          clearTimeout() {},
          matchMedia() {
              return {};
          },
          requestAnimationFrame(callback) {
              if (typeof setTimeout === 'undefined') {
                  callback();
                  return null;
              }
              return setTimeout(callback, 0);
          },
          cancelAnimationFrame(id) {
              if (typeof setTimeout === 'undefined') {
                  return;
              }
              clearTimeout(id);
          },
      };
