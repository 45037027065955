import PropTypes from 'prop-types';
import React from 'react';

import Typography from '../Typography/Typography';

const Text = ({
    tag = 'p',
    variant = 't1',
    color,
    content,
    lineClamp,
    isContainerQuery,
    ...props
}) => {
    return (
        <Typography
            tag={tag}
            variant={variant}
            content={content}
            color={color}
            lineClamp={lineClamp}
            isContainerQuery={isContainerQuery}
            {...props}
        />
    );
};

Text.TAG = {
    P: 'p',
    DIV: 'div',
    SPAN: 'span',
};

Text.VARIANT = {
    T1: 't1',
    T1B: 't1b',
    T1I: 't1i',
    T1L: 't1l',
    T2: 't2',
    T2B: 't2b',
    T2I: 't2i',
    T2L: 't2l',
    T3: 't3',
    T4: 't4',
    E1: 'e1',
    H5: 'h5',
    H6: 'h6',
    CTA1: 'cta1',
    CTA4: 'cta4',
};

Text.COLOR = Typography.COLOR;

Text.propTypes = {
    /**
     * HTML tag of the Text root element
     */
    tag: PropTypes.oneOf(Object.values(Text.TAG)),
    /**
     * Style of the Text content
     */
    variant: PropTypes.oneOf(Object.values(Text.VARIANT)),
    /**
     * Content of the Text
     */
    content: PropTypes.node,
    /**
     * Color of the Text content
     */
    color: PropTypes.oneOf(Object.values(Typography.COLOR)),
    /**
     * Optional Line Clamp Property
     */
    lineClamp: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
            sm: PropTypes.number,
            lg: PropTypes.number,
        }),
    ]),
    /**
     * Sets the typography to use container query styles
     */
    isContainerQuery: PropTypes.bool,
};

export default Text;
