import PropTypes from 'prop-types';
import React from 'react';

import Typography from '../Typography/Typography';

const Title = ({
    tag = 'span',
    variant = 'h3',
    color,
    content,
    lineClamp,
    isContainerQuery,
    ...props
}) => {
    return (
        <Typography
            tag={tag}
            variant={variant}
            content={content}
            color={color}
            lineClamp={lineClamp}
            isContainerQuery={isContainerQuery}
            {...props}
        />
    );
};

Title.TAG = Typography.TAG;

Title.VARIANT = {
    H0: 'h0',
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
    H5: 'h5',
    H6: 'h6',
    T1B: 't1b',
};

Title.COLOR = Typography.COLOR;

Title.propTypes = {
    /**
     * HTML tag of the title root element
     */
    tag: PropTypes.oneOf(Object.values(Typography.TAG)),
    /**
     * Style of the title content
     */
    variant: PropTypes.oneOf(Object.values(Title.VARIANT)),
    /**
     * Content of the title
     */
    content: PropTypes.node,
    /**
     * Color of the title content
     */
    color: PropTypes.oneOf(Object.values(Typography.COLOR)),
    /**
     * Optional Line Clamp Property
     */
    lineClamp: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
            sm: PropTypes.number,
            lg: PropTypes.number,
        }),
    ]),
    /**
     * Sets the typography to use container query styles
     */
    isContainerQuery: PropTypes.bool,
};

export default Title;
