/**
 * PropType to validate an aspect ratio value in the format of Number/Number
 * @param {Object} props - Component properties to test
 * @param {*} propName  - Property name being tested
 * @param {*} componentName  - Name of component being tested
 * @returns {Boolean}
 */
export default function aspectRatio(props, propName, componentName) {
    const value = props[propName];
    if (value && !/[1-9][0-9]*\/[1-9][0-9]*/g.test(value) && value !== 'auto') {
        return new Error(
            `Invalid prop ${propName} supplied to ${componentName}. Expected an aspect ratio in the format "Number/Number" and received "${value}".`,
        );
    }
}
