/**
 * Analytics Scripts
 *
 * To debug analytics, add the query string param `debugAnalytics`
 *
 */
// confirm we are in a browser environment
const isBrowser = typeof window !== 'undefined';
// set default for data layer if not loaded yet
if (isBrowser) window.dataLayer = window.dataLayer || [];

/**
 * Format a DataLayer value by replacing spaces with hyphens and lowercasing the value
 * @param {string} value - Value to format
 * @returns {string}
 */
export const formatValue = (value) => {
    return value
        .replace(/[^a-zA-Z\d\-_\s&]/g, '') //Replace all characters not allowed with an empty value
        .replace(/\s+/g, ' ') // replace repeating white spaces with a single space
        .trim() // after replacing all invalid characters, make sure to trim any leading/trailing whitespaces
        .replace(/(\s+-\s+) /g, '-') // special scenario to replace with hyphen with leading/trailing white spaces
        .replace(/ /g, '_')
        .toLowerCase();
};

export const formatFilters = (searchParams) => {
    const newSearchParams = searchParams || {};
    const filters = [];
    const excludeFields = ['term', 'zip-code'];
    const addFilter = (key, label) => {
        filters.push(
            `${label}: ${newSearchParams
                .getAll(key)
                .map((value) => value.split(';')[0])
                .join(',')}`,
        );
    };
    excludeFields.forEach((key) => newSearchParams.delete(key));

    if (newSearchParams.has('sort')) {
        addFilter('sort', 'SORT BY');
    } else {
        filters.push('SORT BY: Relevancy');
    }
    filters.push(
        `Distance: ${
            newSearchParams.has('distance')
                ? `Within ${newSearchParams.get('distance')} miles`
                : 'Any'
        }`,
    );
    if (!newSearchParams.has('type')) {
        filters.push('Location Type: All');
    }

    for (const key of new Set(newSearchParams.keys())) {
        switch (key) {
            case 'type':
                addFilter(key, 'Location Type');
                break;
            case 'specialty':
                addFilter(key, 'Specialty');
                break;
            case 'condition':
                addFilter(key, 'Condition');
                break;
            case 'treatment':
                addFilter(key, 'Treatment');
                break;
            case 'acc-ins':
                addFilter(key, 'Accepted Insurance');
                break;
            default:
                break;
        }
    }

    return filters.join('|');
};

/**
 * Indicator for enabling analytics debug info via the query string param `debugAnalytics`
 * @returns {Boolean}
 */
const isDebuggingAnalytics = () => {
    if (!isBrowser) {
        return false;
    }
    const params = new URLSearchParams(window.location.search);
    return params.get('debugAnalytics') !== null;
};

// push new event to data layer
const dataLayerPush = (obj) => {
    if (!isBrowser) return;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(obj);
};
const debugAnalytics = (message) => {
    if (isDebuggingAnalytics()) {
        console.log(message);
        console.table(window.dataLayer, [
            'event',
            'interaction_type',
            'link_location',
            'content_group',
            'content_type',
            'component_title',
            'link_text',
            'link_url',
            'interaction_value',
            'selector',
            'search_term',
            'search_position',
            'location_name',
            'location_address',
            'provider_name',
            'provider_specialties',
            'provider_rating',
            'location_parent',
            'location_phone',
            'location_wait',
            'location_open',
            'zip_code',
            'all_filters',
        ]);
    }
};

const getCurrentUrl = () => {
    return window.location.href;
};

const getFirstPagePath = () => {
    return window.location.pathname.split('/')[1];
};

/**
 * Interaction Tracking
 *
 * Event fired when a non-navigation element interaction occurs
 *
 * @param {string} eventName - Event Name
 * @param {string} componentName - parent component container identifier
 * @param {string} componentTitle - parent component container title (this can also be the aria-title)
 * @param {string} actionLabel - Cta label or link text
 * @param {string} selector - data-trigger attribute
 * @param {string} interactionType - context of the interaction e.g. primary_cta, cta, play/pause/video_end, back/breadcrumb
 * @param {string} linkHref - where the cta is point to
 *
 * Search/Location related parameters:
 * @param {string} searchTerm - search value
 * @param {number} searchPosition - position of the search card
 * @param {string} providerName - provider name
 * @param {string} providerSpecialties - provider specialties
 * @param {number} providerRating - provider rating
 * @param {string} locationName - location name
 * @param {string} locationAddress - location address
 * @param {string} locationParent - location parent
 * @param {string} locationPhone - location phone
 * @param {string} locationOpen - location open time
 * @param {string} locationWait - location wait time
 * @param {string} zipCode - location zip code
 * @param {object} allFilters - location search filters as SearchParams
 */
export const trackInteraction = ({
    eventName = 'click',
    componentName = '',
    componentTitle = '',
    actionLabel = '',
    selector = '',
    interactionType = '',
    linkHref = '',
    searchTerm = '',
    searchPosition = '',
    searchFilters = '',
    searchRecommendedCard = '',
    searchResultCount = '',
    providerName = '',
    providerSpecialties = '',
    providerRating,
    locationName = '',
    locationAddress = '',
    locationParent = '',
    locationPhone = '',
    locationOpen = '',
    locationWait = '',
    zipCode = '',
    acceptingNewPatients = '',
    allFilters,
} = {}) => {
    if (isBrowser) {
        const analyticsObj = {};
        const firstPath = getFirstPagePath();

        analyticsObj.event = eventName;
        analyticsObj.interaction_type = interactionType;
        analyticsObj.content_type = formatValue(componentName);
        analyticsObj.component_title = componentTitle;
        analyticsObj.link_text = actionLabel?.replaceAll('\n', ',');
        analyticsObj.selector = selector;
        analyticsObj.link_url = linkHref;
        analyticsObj.content_group = firstPath;
        analyticsObj.link_location = getCurrentUrl();
        analyticsObj.interaction_value = [
            analyticsObj.event,
            analyticsObj.interaction_type,
            analyticsObj.component_title,
            analyticsObj.link_text,
        ]
            .filter((item) => item)
            .join('-');

        // Search related properties
        analyticsObj.search_term = searchTerm;
        analyticsObj.zip_code = zipCode;
        analyticsObj.search_position = searchPosition;
        analyticsObj.tag_filters = searchFilters;
        analyticsObj.recommended_card = searchRecommendedCard;
        analyticsObj.result_count = searchResultCount;
        analyticsObj.provider_name = providerName;
        analyticsObj.provider_specialties = providerSpecialties;
        analyticsObj.provider_rating = providerRating || '';
        analyticsObj.location_name = locationName;
        analyticsObj.location_address = locationAddress;
        analyticsObj.location_parent = locationParent;
        analyticsObj.location_phone = locationPhone;
        analyticsObj.location_open = locationOpen;
        analyticsObj.location_wait = locationWait;
        analyticsObj.accepting_new_patients = acceptingNewPatients;

        if (allFilters) {
            analyticsObj.all_filters = formatFilters(allFilters);
        } else {
            analyticsObj.all_filters = '';
        }

        // inform GTM to clear and not merged with previous data
        analyticsObj._clear = true;

        // push to data layer
        dataLayerPush(analyticsObj);
        debugAnalytics(
            `ui.frontend/src/utils/analytics.js logging window.dataLayer "eventInteraction" event`,
        );
    }
};

/**
 * Page Tracking
 *
 * Event fires when a new page is loaded into the viewport
 *
 */
export const trackPageView = (data = {}) => {
    if (isBrowser) {
        const pageViewObj = {};
        pageViewObj.event = 'pageview';
        pageViewObj.page_location = getCurrentUrl();
        pageViewObj.content_group = getFirstPagePath();
        pageViewObj.page_title = data.pageTitle;
        // push to data layer
        dataLayerPush(pageViewObj);
        debugAnalytics(
            `ui.frontend/src/utils/analytics.js logging window.dataLayer "pageView" event`,
        );
    }
};
