import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Theme.module.scss';

/**
 * Theme component responsible for wrapping other components and exposing CSS variables to its
 * children based on the applied theme name.
 *
 * See theme tokens for available CSS variables.
 */
const Theme = ({ name, children, className }) => (
    <div className={classNames(styles.theme, className, name && styles[`theme--${name}`])}>
        {children}
    </div>
);

/**
 * Theme name enum.
 */
Theme.NAME = {
    DEFAULT: 'default',
    LIGHT: 'light',
    SPECIALTIES: 'specialties',
    CONDITIONS: 'conditions',
    TREATMENTS_AND_SERVICES: 'treatments-and-services',
    PROVIDERS: 'providers',
    LOCATIONS: 'locations',
    MEDIUM: 'medium',
    POSITIVE: 'positive',
    DARK: 'dark',
};

/**
 * Theme prop types.
 * @type {Object}
 * @property {string} name - Theme name.
 * @property {*} children - Theme children.
 */
Theme.propTypes = {
    /**
     * Theme name to apply.
     */
    name: PropTypes.oneOf(Object.values(Theme.NAME)),
    /**
     * Children to wrap with theme.
     */
    children: PropTypes.node.isRequired,
    /**
     * Additional class names to apply.
     */
    className: PropTypes.string,
};

/**
 * Theme default props.
 * @type {Object}
 * @property {string} name - Theme name.
 */
Theme.defaultProps = {
    name: Theme.NAME.DEFAULT,
};

export default Theme;
