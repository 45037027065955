import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Typography.module.scss';

const setLineClamp = (lineClamp) => {
    if (typeof lineClamp === 'number') {
        return {
            '--lines-sm': lineClamp,
            '--lines-lg': lineClamp,
        };
    } else if (typeof lineClamp === 'object' && lineClamp?.sm && lineClamp?.lg) {
        return {
            '--lines-sm': lineClamp.sm,
            '--lines-lg': lineClamp.lg,
        };
    }
};

const Typography = ({
    tag = Typography.TAG.SPAN,
    variant = Typography.VARIANT.T1,
    color = Typography.COLOR.INHERIT,
    lineClamp,
    content,
    className,
    isContainerQuery,
    ...props
}) => {
    const styleClass = `type-${variant}${isContainerQuery ? '-cq' : ''}`;
    const Tag = tag;

    return (
        <Tag
            className={classnames(styles.type, styleClass, styles[`${color}`], className)}
            style={setLineClamp(lineClamp)}
            {...props}
        >
            {content}
        </Tag>
    );
};

Typography.TAG = {
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
    H5: 'h5',
    H6: 'h6',
    P: 'p',
    SPAN: 'span',
    DIV: 'div',
};

Typography.COLOR = {
    INHERIT: 'inherit',
    PRIMARY: 'primary',
};

Typography.VARIANT = {
    H0: 'h0',
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
    H5: 'h5',
    H6: 'h6',
    T1: 't1',
    T1B: 't1b',
    T1I: 't1i',
    T1L: 't1l',
    T2: 't2',
    T2B: 't2b',
    T2I: 't2i',
    T2L: 't2l',
    T3: 't3',
    T4: 't4',
    E1: 'e1',
    CTA1: 'cta1',
    CTA2: 'cta2',
    CTA3: 'cta3',
    CTA4: 'cta4',
    BREADCRUMB: 'breadcrumb',
    LABEL: 'label',
    LABEL1: 'label1',
    LABEL_ACTIVE: 'label-active',
};

Typography.propTypes = {
    /**
     * HTML tag of the typography root element
     */
    tag: PropTypes.oneOf(Object.values(Typography.TAG)),
    /**
     * Style of the typography content
     */
    variant: PropTypes.oneOf(Object.values(Typography.VARIANT)),
    /**
     * Color of the typography content
     */
    color: PropTypes.oneOf(Object.values(Typography.COLOR)),
    /**
     * Content of the typography
     */
    content: PropTypes.node,
    /**
     * Optional Line Clamp Property
     */
    lineClamp: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
            sm: PropTypes.number,
            lg: PropTypes.number,
        }),
    ]),
    /**
     * Sets the typography to use container query styles
     */
    isContainerQuery: PropTypes.bool,
};

Typography.defaultProps = {
    color: Typography.COLOR.PRIMARY,
};

export default Typography;
